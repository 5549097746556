/*eslint-disable */
import { bus } from '@/main';
import toast from '@/services/toast';
export default {
  name: 'upload-presc-data',
  components: {},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    uploadPrescDataPopupClosedMethod(){
      document.body.classList.remove('popup_open_body')
      bus.$emit('uploadPrescDataPopupStateBus', false)
    }
  }
}